import Blocked from "./Blocked";
import { useEffect } from "react";
import io from "socket.io-client";
import moment from "moment-timezone";
import MarketClose from "./MarketClose";
import { makeStyles } from "@mui/styles";
import Logo from "../../src/assets/Tora-Logo.png";
import React, { useMemo, useState } from "react";
import Carousel from "react-material-ui-carousel";
import SubscriptionExpired from "./SubscriptionExpired";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinearProgress from "@mui/material/LinearProgress";
import bullionStats from "../../src/assets/bullionStats.png";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import { Box, Fade, Modal, Typography, useMediaQuery } from "@mui/material";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "100%",
    gap: 3,
  },
  parentContainerError: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "black",
  },
  logoSection: {
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    alignItems: "center",
    margin: "0 2em",
  },
  newsSection: {
    height: "8%",
    backgroundColor: "#172432",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    fontWeight: "400",
  },

  valueShowingSection: {
    height: "60%",
    padding: "1em 3em 0 3em",
    display: "grid",
    gap: "40px",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateAreas: `
      'valueSection ImageSection'
     `,
  },
  valueSection: {
    gridArea: `valueSection`,
    height: "100%",
    display: "grid",
    gap: "0.9em",
    display: "flex",
    flexDirection: "column",
  },
  spotPrices: {
    height: "37%",
    gridArea: `spotPrices`,
    display: "grid",
    gap: "1em",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateAreas: `
      'goldSpot silverSpot'
     `,
  },
  goldSpot: {
    gridArea: `goldSpot`,
    borderRadius: "30px",
    backgroundColor: "#172432",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  goldSpotBody: {
    padding: "0 20px 0 20px",
    display: "flex",
    height: "75%",
    justifyContent: "space-evenly",
  },
  spotBodyValues: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  silverSpot: {
    gridArea: `silverSpot`,
    backgroundColor: "#172432",
    borderRadius: "30px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  silverSpotBody: {
    padding: "0 20px 0 20px",
    display: "flex",
    height: "75%",
    justifyContent: "space-evenly",
  },
  tableValues: {
    gridArea: `tableValues`,
    height: "57%",
  },
  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 4fr",
    gridTemplateAreas: `
        'tabeHeader' 
        'tableContent'
      `,
    gap: "0.5em",
    height: "93%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#172432",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
    paddingLeft: "20px",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "24%",
    flexGrow: 1,
    gridArea: "tableRow1",
    background: "#FFFFFF",
    borderBottom: "3px solid #000000",
    display: "flex",
  },
  tableRow2: {
    flexBasis: 0,
    minHeight: "24%",
    flexGrow: 1,
    gridArea: "tableRow1",
    background: "#FFFFFF",
    borderBottom: "3px solid #000000",
    display: "flex",
  },

  ImageSection: {
    gridArea: `ImageSection`,
    height: "100%",
    display: "grid",
    gridTemplateRows: "2fr 1fr",
    gridTemplateAreas: `
            'goldImage'
            'BuySellData'

         `,
  },
  goldImage: {
    gridArea: `goldImage`,
    borderRadius: "30px",
    maxHeight: "100%",
    minHeight: "100%",
  },
  BuySellData: {
    gridArea: `BuySellData`,
    height: "71%",
    width: "100%",
    marginTop: ".9em",
    background: "#172432",
  },
  stats: {
    padding: "1rem",
  },
  footerSection: {
    height: "8%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#000000",
    marginBottom: "1em",
  },
  footerSectionCopyRights: {
    color: "#FFFFFF",
    fontSize: "2vw",
    display: "flex",
    alignItems: "center",
  },
  footerSectionBullionLogo: {
    display: "flex",
    alignItems: "center",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [openExpireSoon, setOpenExpireSoon] = useState(false);
  const [adminData, setAdminData] = useState();
  const [isMarketClosed, setIsMarketClosed] = useState(false);
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [time, setTime] = useState(new Date());
  const [goldNews, setGoldNews] = useState([]);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  useEffect(() => {
    handleFindSocketUrl();
    handleFinsCommodities();
    handleFindSpread();
    handleFindRatio();
    hadlefetchNews();
    handelFindBanners();
    handleFindGoldPriceNews();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [render, setRender] = useState(true);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });

    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()?.tz(adminData?.time_zone);
      const dayOfWeek = now?.day();
      const hour = now?.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketClosed(true);
      } else {
        setIsMarketClosed(false);
      }
    }, 100); // Check every minute
    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  const handleExpireSoonOpen = () => setOpenExpireSoon(true);
  const handleExpireSoonClose = () => setOpenExpireSoon(false);

  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      });
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      if (!socket) {
        socket = io(res?.socketURL);
        localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
      }
    }
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    return date.toLocaleString([], {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const isTvScreen = useMediaQuery("(min-width: 1000px)");

  ////////////Block//////
  const [openBlocked, setOpenBlocked] = useState(false);
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ////////////////////////////////////////////////////////////
  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      checkSubscriptionExpireSoon(adminData);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  ////////////////////////////////////////////////////////////

  return (
    <div className={openSubscriptionExpierd && classes.blur}>
      <Box className={classes.parentContainer} component="section">
        {isMarketClosed && <MarketClose timeZone={adminData?.time_zone} />}
        <Box className={classes.logoSection} component="section">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#172432",
            }}
          >
            <Typography
              sx={{
                fontSize: "2.2vw",
                fontWeight: "bold",
                color: "white",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {formatDay(time)
                .split("")
                .map((item, index) => (
                  <span key={index}>{item.toUpperCase()}</span>
                ))}
            </Typography>
            <Typography
              sx={{
                fontSize: "2.2vw",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {formatDate(time).toUpperCase()}
            </Typography>
          </Box>
          <img style={{ maxHeight: "100%" }} src={Logo} alt="Amaan-gold-logo" />
          <Box
            sx={{
              display: "flex",
              color: "#172432",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon style={{ fontSize: "60px", color: "white" }} />
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "2.5vw",
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              {formatTime(time)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.newsSection} component="section">
          <marquee behavior="" direction="">
            <Box sx={{ display: "flex" }}>
              {adminData?.isNewsEnable && Object.keys(goldNews).length > 0 ? (
                <Box
                  style={{
                    fontSize: "2vw",
                    fontFamily: "poppins",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Gold Price News</span>
                  &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews.title}</span>
                </Box>
              ) : (
                news?.map((item, index) => {
                  return (
                    <Box
                      key={item._id}
                      style={{
                        fontSize: "2vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {item.newsTitle}
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                      <span
                        style={{ paddingRight: "50px", paddingLeft: "50px" }}
                      >
                        {index === news.length - 1 ? "" : "|"}
                      </span>
                    </Box>
                  );
                })
              )}
            </Box>
          </marquee>
        </Box>
        <Box className={classes.valueShowingSection} component="section">
          <Box className={classes.valueSection} component="article">
            <Box className={classes.spotPrices} component="article">
              <Box className={classes.goldSpot} component="article">
                <Typography
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    borderRadius: "30px 30px",
                    fontWeight: "bold",
                    color: "#172432",
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    bottom: "10px",
                    width: "45%",
                    height: "1.8em",
                    fontSize: "1.6vw",
                  }}
                >
                  GOLD SPOT
                </Typography>
                <Box className={classes.goldSpotBody} component="section">
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                      }}
                    >
                      {displayBidOrBuy.bidOrBuy.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "0.3em",
                        transition: "background-color 0.5s ease",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        padding: "0 0.5em 0 0.5em",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        backgroundColor:
                          Number(gold?.cur?.bid).toFixed(2) <
                          Number(gold?.pre?.bid).toFixed(2)
                            ? "#DE1616"
                            : Number(gold?.cur?.bid).toFixed(2) >
                              Number(gold?.pre?.bid).toFixed(2)
                            ? "#5FA147"
                            : "#000000",
                      }}
                    >
                      {gold?.cur?.bid
                        ? (
                            Number(spread?.goldBidSpread) +
                            Number(gold?.cur?.bid)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                      }}
                    >
                      {displayBidOrBuy.askOrSell.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        marginBottom: "0.3em",
                        fontWeight: "600",
                        fontSize: "2.3vw",
                        padding: "0 0.5em 0 0.3em",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        transition: "background-color 0.5s ease",
                        backgroundColor:
                          Number(gold?.cur?.ask).toFixed(2) <
                          Number(gold?.pre?.ask).toFixed(2)
                            ? "#DE1616"
                            : Number(gold?.cur?.ask).toFixed(2) >
                              Number(gold?.pre?.ask).toFixed(2)
                            ? "#5FA147"
                            : "#000000",
                      }}
                    >
                      {gold?.cur?.ask
                        ? (
                            Number(spread?.goldAskSpread) +
                            Number(gold?.cur?.ask)
                          ).toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "1.6vw",
                      }}
                    >
                      TODAY
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "1.8vw",
                        padding: "0 0.5em 0 0.3em",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.5em",
                        }}
                      >
                        L:&nbsp;
                        {gold?.cur?.lowPrice
                          ? (
                              Number(spread.goldLowSpread) +
                              Number(gold?.cur?.lowPrice)
                            ).toFixed(2)
                          : "0.00"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.5em",
                        }}
                      >
                        H:&nbsp;
                        {gold?.cur?.highPrice
                          ? (
                              Number(spread.goldHighSpread) +
                              Number(gold?.cur?.highPrice)
                            ).toFixed(2)
                          : "0.00"}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.silverSpot} component="article">
                <Typography
                  sx={{
                    display: "flex",
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    borderRadius: "30px 30px",
                    fontWeight: "600",
                    color: "#172432",
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    bottom: "10px",
                    width: "65%",
                    height: "1.8em",
                    fontSize: "1.3vw",
                  }}
                >
                  SILVER SPOT
                </Typography>
                <Box className={classes.silverSpotBody} component="section">
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.3vw",
                      }}
                    >
                      {displayBidOrBuy.bidOrBuy.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.5em",
                        transition: "background-color 0.5s ease",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        backgroundColor:
                          Number(silver?.cur?.bid).toFixed(2) <
                          Number(silver?.pre?.bid).toFixed(2)
                            ? "#DE1616"
                            : Number(silver?.cur?.bid).toFixed(2) >
                              Number(silver?.pre?.bid).toFixed(2)
                            ? "#5FA147"
                            : "#000000",
                      }}
                    >
                      {silver?.cur?.bid
                        ? (
                            Number(spread?.silverBidSpread) +
                            Number(silver?.cur?.bid)
                          ).toFixed(3)
                        : "0.000"}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.3vw",
                      }}
                    >
                      {displayBidOrBuy.askOrSell.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        padding: "0 0.5em 0 0.5em",
                        border: "1px solid #FFFFFF",
                        borderRadius: "10px",
                        color: "#FFFFFF",
                        transition: "background-color 0.5s ease",
                        backgroundColor:
                          Number(silver?.cur?.ask).toFixed(2) <
                          Number(silver?.pre?.ask).toFixed(2)
                            ? "#DE1616"
                            : Number(silver?.cur?.ask).toFixed(2) >
                              Number(silver?.pre?.ask).toFixed(2)
                            ? "#5FA147"
                            : "#000000",
                      }}
                    >
                      {silver?.cur?.ask
                        ? (
                            Number(spread?.silverAskSpread) +
                            Number(silver?.cur?.ask)
                          ).toFixed(3)
                        : "0.000"}
                    </Typography>
                  </Box>
                  <Box component="section" className={classes.spotBodyValues}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.2vw",
                      }}
                    >
                      TODAY
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.6vw",
                        padding: "0 0.5em 0 0.3em",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.4em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        L:&nbsp;
                        {silver?.cur?.lowPrice
                          ? (
                              Number(spread.silverLowSpread) +
                              Number(silver?.cur?.lowPrice)
                            ).toFixed(3)
                          : "0.000"}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.4em",
                          whiteSpace: "nowrap",
                        }}
                      >
                        H:&nbsp;
                        {silver?.cur?.highPrice
                          ? (
                              Number(spread.silverHighSpread) +
                              Number(silver?.cur?.highPrice)
                            ).toFixed(3)
                          : "0.000"}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.tableValues} component="article">
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    WEIGHT
                  </Typography>

                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      fontSize: "2vw",
                      fontWeight: "bold",
                      color: "#D4A638",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 1 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>
                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity?.display_metal
                      ? commodity?.display_metal.split(" ")
                      : commodity?.commodity_title.split(" ");
                    const isEvenRow = idx % 2 === 0;
                    return (
                      <Box
                        key={idx}
                        className={`${
                          isEvenRow ? classes.tableRow2 : classes.tableRow1
                        } ${classes.tableRow}`}
                      >
                        <Typography
                          style={{
                            fontSize: "1.4vw",
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "20px",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    color: "black",
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {word?.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    color: "black",
                                    fontSize: "1vw",
                                    marginTop: "0.4rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;
                                  {commodity?.display_metal
                                    ? word?.toUpperCase()
                                    : word === "Kilobar" ||
                                      word === "Coin" ||
                                      word === "Bar"
                                    ? ""
                                    : word?.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "1.4vw",
                              color: "black",
                              marginTop: "0.3em",
                              fontWeight: "bold",
                            }}
                          >
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                        </Typography>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            flex: ".9",
                            display: "flex",
                            alignItems: "center",
                            color: "#000000",
                            fontSize: "2vw",
                            marginLeft: ".9em",
                          }}
                          // className={classes.tableRowColumn}
                        >
                          {commodity.unit}
                          {commodity.unitLabel}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "1.8vw",
                            color: "black",
                            fontWeight: "bold",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {gold?.cur?.ask
                            ? commodityCalculation(
                                commodity.commodity_title === "Silver"
                                  ? silver?.cur?.ask
                                  : gold?.cur?.ask,
                                commodity.commodity_title === "Silver"
                                  ? spread.silverAskSpread
                                  : spread.goldAskSpread,
                                commodity.premium,
                                3.674,
                                commodity.purity,
                                commodity.unit,
                                commodity.unitLabel === "TTB"
                                  ? 116.64
                                  : commodity.unitLabel === "KG"
                                  ? 1000
                                  : commodity.unitLabel === "OZ"
                                  ? 31.1
                                  : commodity.unitLabel === "TOLA"
                                  ? 11.7
                                  : 1,
                                commodity.charges
                              )
                            : "0.00"}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.ImageSection}>
            <Box className={classes.goldImage} component="article">
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  maxHeight: "300px",
                  borderRadius: "30px",
                  minHeight: "300px",
                }}
                transitionTime={3000}
                autoPlay
                duration={2000}
              >
                {banners?.map((item, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "30px",
                    }}
                    src={item.imageUrl}
                    alt="Rectangle-Gold-Image"
                  />
                ))}
              </Carousel>
            </Box>
            <Box className={classes.BuySellData} component="article">
              <Box className={classes.stats}>
                <Box className={classes.percent}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 5,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.2vw", color: "#009E60" }}>
                      BUYERS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        color:
                          Number(ratio?.chgValue.replace("%", "")) >= 0
                            ? "#1C6DB9"
                            : "#D03933",
                      }}
                    >
                      {ratio?.chgValue}
                    </Typography>
                    <Typography sx={{ fontSize: "1.2vw", color: "#A90011" }}>
                      SELLERS
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      style={{ backgroundColor: "#A90011" }}
                      sx={{
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#009E60",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      px: 7,
                      py: 1,
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.3vw", color: "#009E60" }}>
                      {ratio?.Buyers}
                    </Typography>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        marginTop: 3,
                        height: "1.7vw",
                      }}
                      src={bullionStats}
                      alt="Bullion-Logo"
                    />
                    <Typography sx={{ fontSize: "1.3vw", color: "#A90011" }}>
                      {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openExpireSoon}
        onClose={handleExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <Box>
          <SubscriptionExpiringSoon
            date={adminData?.package?.expire_date}
            handleClose={handleExpireSoonClose}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleCloseBlocked} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <Blocked handleClose={handleCloseBlocked} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
