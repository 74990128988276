import { Box, Typography } from "@mui/material";
import bullionStats from "../assets/logowithname.png";
import image from "../assets/Group.png";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Moment from "react-moment";

const main = {
  position: "absolute",
  bottom: 10,
  right: 10,
  width: 450,
  bgcolor: "#000000",
  borderRadius: "10px",
  p: 3,
  outline: "none",
  display: "flex",
  justifyContent: "space-between",
};

const style2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "5px",
};
const test = {
  color: "white",
  fontFamily: "poppins",
  fontSize: 15,
  textAlign: "center",
  lineHeight: 1.3,
};
const number = {
  color: "white",
  fontFamily: "poppins",
  fontSize: 20,
  textAlign: "center",
};

export default function SubscriptionExpiringSoon({ handleClose, date }) {
  return (
    <Box sx={main}>
      <Box sx={style2}>
        <Typography
          variant="h4"
          sx={test}
          style={{ fontSize: 25, color: "#F33B37" }}
        >
          Your Subscription Expiring Soon
        </Typography>
        <Typography sx={test}>
          {" "}
          Your subscription will be &nbsp;
          <span style={{ color: "#5CA3E8" }}>
            expiring on &nbsp;
            <Moment
              element="span"
              style={{ width: "100%" }}
              format="MMM Do YYYY"
            >
              {date}
            </Moment>
            . <br />
          </span>
          Please subscribe to continue our service
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <WhatsAppIcon sx={{ color: "white" }} />
          <Typography sx={number}>Support: +971 55 705 2211</Typography>
        </Box>
      </Box>
      <HighlightOffIcon
        sx={{ color: "white", width: "40px", cursor: "pointer" }}
        onClick={handleClose}
      />
    </Box>
  );
}
